import { RefObject, useEffect, useRef, useState } from 'react';
import { UnsafeAny } from '@ts/General';
import { cloneDeep } from 'lodash-es';

const clickOutside = (ref: RefObject<HTMLInputElement>, callback: () => void) => {
  const handleClick = (e: MouseEvent) => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    if (ref && ref.current && !ref.current.contains(e.target)) {
      callback();
    }
  };

  // eslint-disable-next-line react-hooks/rules-of-hooks
  useEffect(() => {
    document.addEventListener('click', (e) => handleClick(e));

    return () => document.removeEventListener('click', (e) => handleClick(e));
  });
};

const prevValue = (value: UnsafeAny) => {
  const prevValueRef = useRef();

  useEffect(() => {
    prevValueRef.current = typeof value === 'object' ? cloneDeep(value) : value;
  }, [value]);

  return prevValueRef.current;
};

const useScrollY = (ref?: UnsafeAny) => {
  console.log(ref?.offsetTop);
  const [scrollY, setScrollY] = useState(0);

  useEffect(() => {
    // Function to update scrollY value

    const node = ref || window;

    function handleScroll() {
      setScrollY(ref ? ref.scrollTop : node.scrollY);
    }

    // Add event listener for scroll
    node.addEventListener('scroll', handleScroll);

    // Clean up the event listener when the component unmounts
    return () => {
      node.removeEventListener('scroll', handleScroll);
    };
  }, [ref]);

  return scrollY;
};

const useDelay = (value: UnsafeAny, delay = 100) => {
  const [state, setState] = useState(value);

  useEffect(() => {
    const timeout = setTimeout(() => setState(value), delay);

    return () => clearTimeout(timeout);
  }, [value]);

  return state;
};

export default {
  prevValue,
  clickOutside,
  useScrollY,
  useDelay,
};
