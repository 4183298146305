// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".wrap-8VkhU{display:flex;flex-direction:column;justify-content:space-evenly;align-items:center;color:#fff;min-height:90svh;text-transform:uppercase;font-size:12px;letter-spacing:1px}.date-1y9Xl{display:flex}.labelSecondary-35Ska{opacity:.4;padding:0 6px}.stats-3HeFo{display:flex;align-items:center}.stat-2pwHQ,.statSecondary-2ywwS{display:flex;flex-direction:column;justify-content:center;align-items:center;border-radius:50%;margin:16px;min-width:120px;min-height:120px;padding:20px;border:2px solid #ddd}.label-3LZny{font-size:10px}.value-XOVTW{font-size:50px;font-weight:100;line-height:1}.statSecondary-2ywwS{min-width:100px;min-height:100px;opacity:.7;padding:16px;border-color:#555}.statSecondary-2ywwS .value-XOVTW{font-size:30px}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrap": "wrap-8VkhU",
	"date": "date-1y9Xl",
	"labelSecondary": "labelSecondary-35Ska",
	"stats": "stats-3HeFo",
	"stat": "stat-2pwHQ",
	"statSecondary": "statSecondary-2ywwS",
	"label": "label-3LZny",
	"value": "value-XOVTW"
};
export default ___CSS_LOADER_EXPORT___;
