// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".wrap-1CGuL{flex:1;display:flex;align-items:center;justify-content:center}.content-15hK2{display:flex;flex-direction:column}.areaWrap-2E084{display:flex;justify-content:center;margin-bottom:100px}.area-NaLRZ{display:flex;flex-direction:column;background:#18181b;border-radius:20px;min-width:650px;box-shadow:0 10px 40px}.areaBody-2OgsQ{display:flex;padding:20px 20px 20px 30px}.areaFilters-2W60x{display:flex;gap:10px;border-top:2px solid rgba(255,255,255,.0666666667);padding:10px 16px 6px 20px}.iconButton-1eFRc{display:flex;justify-content:center;align-items:center;min-width:54px;min-height:54px;border-radius:10px}.iconButton-1eFRc.active-IfWd2{background:rgba(56,149,211,.1333333333) !important}.iconButton-1eFRc.active-IfWd2 .icon-21_Cp{color:#3895d3 !important}.filterButton-2BJsH{opacity:.3;transition:.3s ease all}.active-IfWd2{opacity:1}.icon-21_Cp{color:#fff}.area-NaLRZ input{font-family:\"Poppins\",sans-serif}.todo-5IN00{display:flex;align-items:center;justify-content:space-between;padding:4px 4px 4px 20px;border-radius:10px;border:3px solid #fff;color:#222}.todoButton-1iaIj{padding:10px !important;border-radius:6px !important;background:#3895d3 !important;color:#fff !important}.todo-5IN00.active-IfWd2{background:#333 !important;color:#fff !important;transition:.3s ease all;border:3px solid rgba(0,0,0,0)}.todo-5IN00.active-IfWd2 .todoButton-1iaIj{background:rgba(0,0,0,0) !important}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrap": "wrap-1CGuL",
	"content": "content-15hK2",
	"areaWrap": "areaWrap-2E084",
	"area": "area-NaLRZ",
	"areaBody": "areaBody-2OgsQ",
	"areaFilters": "areaFilters-2W60x",
	"iconButton": "iconButton-1eFRc",
	"active": "active-IfWd2",
	"icon": "icon-21_Cp",
	"filterButton": "filterButton-2BJsH",
	"todo": "todo-5IN00",
	"todoButton": "todoButton-1iaIj"
};
export default ___CSS_LOADER_EXPORT___;
