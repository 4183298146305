// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".wiki-wrap-21flQ{display:flex;flex-direction:column}.wiki-header-2Czgw{background:#222;min-height:40px}.wiki-header-title-2OS8U{font-size:18px;color:#fff;padding:10px 20px}.wiki-container-XfFYp{display:flex;min-height:calc(100svh - 47px)}.wiki-content-1NmMx{display:flex;flex-direction:column;min-height:100%;background:#fff;color:#555;font-size:15px;font-family:\"Open Sans\",sans-serif;flex:1}.wiki-menu-1gH-Y{display:flex;flex-direction:column;min-width:240px;background:#333}.wiki-menu-item-3RyDE{display:flex;align-items:center;padding:14px 20px;color:#ddd;cursor:pointer}.wiki-menu-item-3RyDE:hover{background:#282828;color:#fff}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wiki-wrap": "wiki-wrap-21flQ",
	"wiki-header": "wiki-header-2Czgw",
	"wiki-header-title": "wiki-header-title-2OS8U",
	"wiki-container": "wiki-container-XfFYp",
	"wiki-content": "wiki-content-1NmMx",
	"wiki-menu": "wiki-menu-1gH-Y",
	"wiki-menu-item": "wiki-menu-item-3RyDE"
};
export default ___CSS_LOADER_EXPORT___;
