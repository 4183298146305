// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".toolbar-34Rhw{background:#222;border-radius:40px;box-shadow:0 2px 20px 0 rgba(0,0,0,.5)}.toolbarBody-yvUGG{position:relative;margin:2px;display:flex}.slider-1PtyR{position:absolute;left:0;top:0;height:100%;background:#e9e9e9;border-radius:50px;z-index:0;flex-grow:1;transition:.3s ease all}.toolbarButton-3bDrI{min-width:120px;padding:8px 10px !important;text-transform:uppercase;letter-spacing:3px;font-weight:500;color:#ccc !important;font-size:14px !important;border-radius:50px !important}.toolbarButton-3bDrI.active-1vT1P{color:#222 !important;transition:.3s ease all}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"toolbar": "toolbar-34Rhw",
	"toolbarBody": "toolbarBody-yvUGG",
	"slider": "slider-1PtyR",
	"toolbarButton": "toolbarButton-3bDrI",
	"active": "active-1vT1P"
};
export default ___CSS_LOADER_EXPORT___;
