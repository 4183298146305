// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".button-1EtXf{opacity:.85;font-size:12px;letter-spacing:1px;font-weight:600;text-transform:uppercase;padding:8px 12px;background:#00bfff;border-radius:100px;color:#fff}.button-1EtXf:before{content:\"\";position:absolute;top:50%;left:50%;width:20px;height:20px;margin-top:-12px;margin-left:-12px;border-radius:100px;transition:.2s linear opacity;opacity:0}.button-1EtXf.error-20mBV{background:red !important;border-color:red}.button-1EtXf:before.loading-22RtZ{border:2px solid #fff;border-top-color:rgba(0,0,0,0);animation:rotate-3lVzb .8s linear infinite;transition:.3s .1s linear opacity;opacity:1}.button-1EtXf.loading-22RtZ .label-2Pf3M{opacity:0}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"button": "button-1EtXf",
	"error": "error-20mBV",
	"loading": "loading-22RtZ",
	"rotate": "rotate-3lVzb",
	"label": "label-2Pf3M"
};
export default ___CSS_LOADER_EXPORT___;
