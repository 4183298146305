// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".wrap-2INu7{background:#fff;min-height:100svh}.header-PWBnp{background:#333;color:#fff;display:flex;justify-content:space-between}.imageWrap-1VbkK{aspect-ratio:16/9;overflow:hidden}.image-2OwEP{width:100%}.body-3-Eha{padding:40px 0;display:flex;flex-direction:column;align-items:center}.grid-XBNG_,.gridPortrait-2MxCA{display:grid;grid-template-columns:400px 400px 400px;grid-template-rows:auto;column-gap:30px;row-gap:30px}.gridPortrait-2MxCA{margin-top:30px;grid-template-columns:228px 228px 228px 228px 228px}.gridItem-23xgk{aspect-ratio:16/9;overflow:hidden}.gridItemPortrait-Xm47T{aspect-ratio:3/4;overflow:hidden}.backButton-dQvoy{color:#fff;cursor:pointer;padding:10px 20px;background:#333;display:flex;justify-content:center;align-items:center;text-transform:uppercase}.flags-2OKdv{display:flex}.flag-dyz_H{display:flex;align-items:center;padding:14px 20px;color:#555;font-weight:bolder;text-transform:uppercase;font-size:12px;cursor:pointer}.flag-dyz_H:hover{color:#aaa}.flag-dyz_H.active-2u1hC{color:#fff}@media(max-width: 900px){.grid-XBNG_{grid-template-columns:400px 400px}.gridPortrait-2MxCA{grid-template-columns:228px 228px 228px}}@media(max-width: 700px){.grid-XBNG_{grid-template-columns:400px}.gridPortrait-2MxCA{grid-template-columns:228px 228px}}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrap": "wrap-2INu7",
	"header": "header-PWBnp",
	"imageWrap": "imageWrap-1VbkK",
	"image": "image-2OwEP",
	"body": "body-3-Eha",
	"grid": "grid-XBNG_",
	"gridPortrait": "gridPortrait-2MxCA",
	"gridItem": "gridItem-23xgk",
	"gridItemPortrait": "gridItemPortrait-Xm47T",
	"backButton": "backButton-dQvoy",
	"flags": "flags-2OKdv",
	"flag": "flag-dyz_H",
	"active": "active-2u1hC"
};
export default ___CSS_LOADER_EXPORT___;
