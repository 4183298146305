// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".stats-hrBOI{position:relative;padding-top:12px}.statsInfo-1tcFP{top:0;width:100%;display:flex;padding:8px 0;justify-content:space-evenly}.stat-3C7dj{color:#333;display:flex;flex-direction:column;align-items:center}.statLabel-2mNqY{font-size:10px;opacity:.4}.statsType-WaIXv{display:flex;justify-content:center;margin:4px 0 12px}.multiPickerButton-3n_t7{border:2px solid #eee !important;padding:2px 12px !important;border-right:1px solid #eee !important;color:#aaa;text-transform:uppercase;letter-spacing:1px;font-size:11px;font-weight:500;font-family:\"Poppins\",sans-serif}.multiPickerButton-3n_t7:first-child{border-top-left-radius:6px !important;border-bottom-left-radius:6px !important}.multiPickerButton-3n_t7:last-child{border-top-right-radius:6px !important;border-bottom-right-radius:6px !important;border-right:2px solid #eee !important}.multiPickerButton-3n_t7.selected-3BV7K{color:#333;background:#eee}@media(max-width: 1024px){.statsInfo-1tcFP{padding:0}}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"stats": "stats-hrBOI",
	"statsInfo": "statsInfo-1tcFP",
	"stat": "stat-3C7dj",
	"statLabel": "statLabel-2mNqY",
	"statsType": "statsType-WaIXv",
	"multiPickerButton": "multiPickerButton-3n_t7",
	"selected": "selected-3BV7K"
};
export default ___CSS_LOADER_EXPORT___;
