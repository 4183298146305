import React from 'react';
import { Provider } from 'react-redux';
import { BrowserRouter as Router, Redirect, Route, Switch } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import store from './redux/store';

/** Base **/
import Layout from '@layout/index';
import Home from '@pages/Home';
import Projects from './components/pages/Projects';
// import Portfolio from './components/pages/Portfolio';
import PortfolioCategorized from './components/pages/PortfolioCategorized';
import AnalogPortfolio from '@pages/AnalogPortfolio';
import AboutNew from '@pages/AboutNew';

/** File storage **/
import Files from './components/pages/extra/Files';
import File from './components/pages/extra/File';

/** Vision **/
import Vision from './components/pages/Vision/pages/Detail';
import Visions from './components/pages/Vision/pages/Home';

/** Votes **/
import Voting from './components/pages/Votes/Detail';
import Votes from './components/pages/Votes/Home';

/** Films **/
import Films from '@pages/Films';

/** Single **/
import Music from '@pages/Music/Overview';
import Life from './components/pages/Life';
import Stuff from './components/pages/Stuff/List';

/** Games **/
// import Games from './components/pages/Games/Overview';
import GameStats from './components/pages/Games/pages/Stats';
import GameDashboard from './components/pages/Games/pages/Dashboard';
import GameLeaderboard from './components/pages/Games/pages/Leaderboard';
import GameRecords from './components/pages/Games/pages/Records';
import GameTypes from './components/pages/Games/pages/Types';

/** Drinks **/
import DrinkOverview from './components/pages/Drinks/Overview';
import DrinkTypes from './components/pages/Drinks/pages/Types';
import DrinkHistory from './components/pages/Drinks/pages/History';

/** Picks **/

import PickOverview from '@pages/Pick/Oveview';
import PickTypes from '@pages/Pick/pages/Types';

/** F1 **/
import F1 from '@pages/F1/pages/News';
import F1Vote from './components/pages/F1/pages/Voting';
import F1Results from '@pages/F1/pages/Results';
import F1Teams from '@pages/F1/pages/Teams';
import F1Drivers from '@pages/F1/pages/Drivers';
import F1Circuits from './components/pages/F1/pages/Circuits';
import F1Admin from '@pages/F1/pages/Admin';

/** Apps **/
import Develop from './components/pages/extra/Develop';
import DevelopApp from './components/pages/extra/Develop/App';
import PrivacyPolicy from './components/pages/extra/Develop/App/PrivacyPolicy';

/** Extra */
import Wiki from './components/pages/extra/Wiki';
import Clipboard from './components/pages/extra/Clipboard';

import LifeLayout from '@pages/Life/layout';

import Version from './components/pages/utils/Version';

/** Utils **/
import ImageTagger from './components/pages/utils/ImageTagger';
import NotFound from './components/utils/NotFound';
import F1DataProvider from '@context/f1Data';
import GamesDataProvider from '@context/gamesData';
import DrinksDataProvider from '@context/drinksData';
import { ThemeProvider, createTheme } from '@mui/material';
import { Token } from './hooks';
import Loading from '@common/Loading';
import Login from './components/admin/Login';

const theme = createTheme({
  // Theme settings
  palette: {
    mode: 'dark',
  },
});

type RouteProps = {
  loggedIn: boolean | null;
  component: React.ComponentType<any>;
} & React.ComponentProps<typeof Route>;

const AuthenticatedRoute = ({ loggedIn, component: Component, ...rest }: RouteProps) => {
  if (loggedIn === null) {
    return <Loading />;
  }

  if (!loggedIn) {
    if (window.location.pathname !== '/authorize') {
      localStorage.setItem('redirect', window.location.pathname);
    }
    return <Redirect to="/authorize" />;
  }

  return <Route {...rest} render={(props) => <Component {...props} />} />;
};

export const App = () => {
  const loggedIn = Token.useTokenValidation();

  return (
    <>
      <Provider store={store}>
        <ThemeProvider theme={theme}>
          <Layout>
            <Router>
              <DrinksDataProvider>
                <GamesDataProvider>
                  <F1DataProvider>
                    <Switch>
                      <Route path="/" exact component={Home} />
                      <Route path="/portfolio/analog" component={AnalogPortfolio} />
                      <Route path="/portfolio/*" component={PortfolioCategorized} />
                      <Route path="/projects" component={Projects} />
                      <Route path="/about" component={AboutNew} />

                      <Route path="/authorize" exact component={Login} />
                      <Route path="/login" exact component={Login} />

                      <Route path="/file/*" component={File} />
                      <Route path="/files" component={Files} />

                      <Route path="/vision/:type" component={Vision} />
                      <Route path="/visions" component={Visions} />

                      <Route path="/voting/*" component={Voting} />
                      <Route path="/votes" component={Votes} />

                      <Route path="/stuff" component={Stuff} />

                      <AuthenticatedRoute path="/life" component={LifeLayout} loggedIn={loggedIn} />

                      <Route path="/music" component={Music} />

                      <Route path="/games" component={GameDashboard} exact />
                      <Route path="/games/stats" component={GameStats} exact />
                      <Route path="/games/records" component={GameRecords} exact />
                      <Route path="/games/leaderboard" component={GameLeaderboard} exact />
                      <Route path="/games/types" component={GameTypes} exact />

                      <Route path="/drinks" component={DrinkOverview} exact />
                      <Route path="/drinks/current" component={DrinkOverview} exact />
                      <Route path="/drinks/types" component={DrinkTypes} exact />
                      <Route path="/drinks/history" component={DrinkHistory} exact />

                      <Route path="/picks" component={PickOverview} exact />
                      <Route path="/picks/types" component={PickTypes} exact />

                      <Route path="/f1" exact component={F1} />
                      <Route path="/f1/vote" component={F1Vote} />
                      <Route path="/f1/drivers" component={F1Drivers} />
                      <Route path="/f1/teams" component={F1Teams} />
                      <Route path="/f1/circuits" component={F1Circuits} />
                      <Route path="/f1/results" component={F1Results} />
                      <Route path="/f1/admin" component={F1Admin} />

                      <Route path="/films" component={Films} />
                      <Route path="/develop" component={Develop} />
                      <Route path="/clipboard" component={Clipboard} />
                      <Route path="/apps/privacy/*" component={PrivacyPolicy} />
                      <Route path="/apps/*" component={DevelopApp} />

                      <Route path="/wiki" component={Wiki} />

                      <Route path="/images" component={ImageTagger} />

                      <Route path="/version" component={Version} />

                      <Route path="/pick" render={() => <Redirect to="/picks" />} />
                      <Route path="/drink" render={() => <Redirect to="/drinks" />} />
                      <Route path="/clip" render={() => <Redirect to="/clipboard" />} />
                      <Route path="/clips" render={() => <Redirect to="/clipboard" />} />

                      <Route path="*" component={NotFound} />
                    </Switch>
                  </F1DataProvider>
                </GamesDataProvider>
              </DrinksDataProvider>
            </Router>
          </Layout>
        </ThemeProvider>
      </Provider>
    </>
  );
};
