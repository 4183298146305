// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".wrap-3YRR_{padding:30px;display:flex;flex:1}.content-1iAoa{display:flex;justify-content:center;align-items:start;gap:50px;flex:1}.body-37ge8{display:flex;gap:50px;flex-wrap:wrap}.group-IL1TA{background:#222;box-shadow:0 2px 20px 0 rgba(0,0,0,.5);border-radius:28px;padding:20px 30px;color:#fff;text-transform:uppercase;min-width:300px;letter-spacing:1px;box-sizing:border-box}.group-IL1TA.white-2LTEH{background:#dedede;box-shadow:0 2px 60px 0 rgba(0,0,0,.26);border-radius:28px}.actions-3H2kg{display:flex;gap:20px;justify-content:center;text-transform:uppercase}.adminButton-2XSF9{padding:6px 10px !important;border-radius:6px !important;color:gray !important}@media(max-width: 1024px){.content-1iAoa{flex-direction:column}.body-37ge8,.content-1iAoa{gap:30px}}@media(max-width: 600px){.wrap-3YRR_{padding:30px;gap:20px}.content-1iAoa{align-items:center}.body-37ge8,.content-1iAoa{gap:12px;align-items:center}.group-IL1TA{width:100%}}@media(max-width: 400px){.wrap-3YRR_{padding:15px}}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrap": "wrap-3YRR_",
	"content": "content-1iAoa",
	"body": "body-37ge8",
	"group": "group-IL1TA",
	"white": "white-2LTEH",
	"actions": "actions-3H2kg",
	"adminButton": "adminButton-2XSF9"
};
export default ___CSS_LOADER_EXPORT___;
