// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".item-3QbwI{display:flex;justify-content:space-between;align-items:center;padding:4px 0;margin-bottom:4px}.itemBody-1WgA_{display:flex;align-items:center;font-size:16px;text-transform:uppercase}.itemHelper-1xfsd{opacity:.2;margin-right:12px;font-size:34px;min-width:26px}.itemTime-1wocE{font-size:11px;opacity:.3;text-align:left}.itemActions-2njco{display:flex;align-items:center}.buttonEdit-3NhqT{border-radius:4px !important;padding-right:12px !important}.actionIcon-11cEt{opacity:.6;font-size:18px !important;margin-right:12px !important}.textFieldDate-6N_qU{margin:0 40px}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"item": "item-3QbwI",
	"itemBody": "itemBody-1WgA_",
	"itemHelper": "itemHelper-1xfsd",
	"itemTime": "itemTime-1wocE",
	"itemActions": "itemActions-2njco",
	"buttonEdit": "buttonEdit-3NhqT",
	"actionIcon": "actionIcon-11cEt",
	"textFieldDate": "textFieldDate-6N_qU"
};
export default ___CSS_LOADER_EXPORT___;
