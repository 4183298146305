// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".button-3_0jm,.buttonMain-gOnIU{padding:10px 20px !important;flex:1}.button-3_0jm.active-3LHQw,.buttonMain-gOnIU.active-3LHQw{background:#0af;color:#fff}.body-2mYpC{display:flex;flex-direction:column}.title-1-BVX{padding:10px 20px}.body-2mYpC{padding:10px 20px}.field-IOeCH{margin-bottom:10px !important}.counter-12svT{display:flex;justify-content:space-between;align-items:center}.item-3-Pf7{display:flex;justify-content:space-between;align-items:center;border-radius:6px;background:rgba(0,0,0,.2666666667);font-size:14px;overflow:hidden}.itemBody-14bi1{padding:10px 20px}.addItem-3a5YA{padding:10px;background:rgba(0,0,0,.2666666667);border-radius:6px}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"button": "button-3_0jm",
	"buttonMain": "buttonMain-gOnIU",
	"active": "active-3LHQw",
	"body": "body-2mYpC",
	"title": "title-1-BVX",
	"field": "field-IOeCH",
	"counter": "counter-12svT",
	"item": "item-3-Pf7",
	"itemBody": "itemBody-14bi1",
	"addItem": "addItem-3a5YA"
};
export default ___CSS_LOADER_EXPORT___;
