import React, { useEffect, useState } from 'react';
import classNames from 'classnames';
import { ButtonBase, Fade } from '@mui/material';
import { KeyboardArrowDown } from '@mui/icons-material';

import hooks from '@functions/hooks';

import { Common, Hooks } from '@functions';

import PageWrap from '../../utils/PageWrap';
import Timeline from '@common/Timeline';
import Icon from '@common/SVG';

import about from '@assets/about';

import aboutImage from '@assets/img/about-new.jpg';
import aboutImage2 from '@assets/img/about-new-2.jpg';

import styles from './style.scss';

const About = (): JSX.Element => {
  const [unmount] = useState<string | null>(null);

  const [imgLoaded, setImgLoaded] = useState(false);
  const [img2Loaded, setImg2Loaded] = useState(false);

  const scrollY = hooks.useScrollY();
  const activeGapMin = 10;
  const activeGapMax = window.innerHeight - 300;

  const prevScrollY = Hooks.prevValue(scrollY) as number | undefined;

  useEffect(() => {
    if (window.innerWidth > 1360) {
      Common.typingCallBack(() => {
        if (scrollY > activeGapMin && scrollY < activeGapMax) {
          if ((prevScrollY || 0) < scrollY) {
            window.scrollTo({
              top: window.innerHeight,
              behavior: 'smooth',
            });
          } else {
            window.scrollTo({
              top: 0,
              behavior: 'smooth',
            });
          }
        }
      }, 600);
    }
  }, [scrollY]);

  const renderProgramEquip = () => {
    const items = ['lightroom', 'photoshop', 'premier'];

    return items.map((item, key) => (
      <div className="about-item-program" key={key}>
        <Icon icon={item} height={30} width={30} color="#777" />
      </div>
    ));
  };

  return (
    <PageWrap unmount={unmount}>
      <div className={styles.wrap}>
        <div className={styles.aboutSection}>
          <div className={styles.partMain}>
            <div className={styles.aboutTitle}>
              <h1 className={styles.aboutTitleFirstName}>Dominik</h1>
              <h3 className={styles.aboutTitleSurname}>Láclavík</h3>
            </div>
          </div>
          <div className={styles.part}>
            <div className={styles.partMain}>
              <img
                className={classNames(styles.image, imgLoaded && styles.loaded)}
                onLoad={() => setImgLoaded(true)}
                src={aboutImage}
                alt="about_pic"
              />
            </div>
            <div className={styles.part}>
              <div className={styles.partContent}>
                <h6 className={styles.partTitle}>About me</h6>
                <p className={styles.partText}>{about.aboutMe}</p>
              </div>
            </div>
            <Fade in={scrollY < 100}>
              <div>
                <div className={styles.scrollBanner}>
                  <ButtonBase
                    onClick={() =>
                      window.scrollTo({
                        top: window.innerHeight,
                        behavior: 'smooth',
                      })
                    }
                    classes={{ root: styles.scrollButton }}
                  >
                    <KeyboardArrowDown classes={{ root: styles.scrollButtonIcon }} />
                    Explore more
                  </ButtonBase>
                </div>
              </div>
            </Fade>
          </div>
          <div className={styles.part}>
            <div className={styles.part}>
              <div className={styles.partContent}>
                <h6 className={styles.partTitle}>Exploring beautiful places in the world</h6>
                <p className={styles.partText}>{about.aboutExploring}</p>
              </div>
            </div>
            <div className={styles.partMain}>
              <img
                className={classNames(styles.imageBottom, img2Loaded && styles.loaded)}
                onLoad={() => setImg2Loaded(true)}
                src={aboutImage2}
                alt="about_pic_2"
              />
            </div>
          </div>
        </div>
        <div className={styles.timelineSection}>
          <div className={styles.timelineHeader}>
            <div className={styles.timelineTitle}>Timeline</div>
          </div>
          <Timeline config={about.timeline} />
        </div>
      </div>
    </PageWrap>
  );
};

export default About;
