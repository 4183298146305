// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".wrap-10ogN{padding:80px;max-width:800px}.name-B1k0k{font-size:36px;font-weight:bolder}.block-3jXHC:nth-child(even){background:#f2f2f4}.promotion-2x0II,.description-2F1sc,.name-B1k0k,.icons-vrSSm{margin-bottom:12px}.promotion-2x0II{font-weight:bolder}.promotion-2x0II,.description-2F1sc{white-space:pre-wrap}.more-3lJ5G{margin-bottom:4px}.tags-31BdL{display:flex;margin:14px 0}.tag-3LkG5{font-size:11px;text-transform:uppercase;margin-right:10px;color:#bbb;font-weight:bold;padding:2px 5px;border-radius:4px;border:1px solid #ddd}.pages-2CF6-{font-size:11px;text-transform:uppercase;color:#bbb;font-weight:bold}.page-3p6pZ{color:#00bfff;margin:0 6px;text-transform:initial;font-weight:normal;font-size:14px}.page-3p6pZ:hover{text-decoration:underline}.tags-31BdL{display:flex;margin:14px 0}.links-2w9kb{display:flex;margin:10px 0}.link-3B8YN{margin-right:20px}.links-2w9kb a{color:#00bfff}.links-2w9kb a:hover{text-decoration:underline}.bar-block-1znHN{display:flex;margin-right:20px;font-weight:bolder;font-size:11px;letter-spacing:1px;text-transform:uppercase;color:#999;align-items:center;width:300px;justify-content:space-between}.barDots-28bbb{display:flex;width:140px;justify-content:space-between}.barDot-3avIf{width:10px;height:10px;border-radius:10px;background:#ddd;box-sizing:border-box}.barDot-3avIf.filled-3aKlE{background:#00bfff}.bodyBlock-2p_0U{display:flex;flex-direction:column;padding:8px 0}.bodyBlockTitle-14-aV{font-weight:bold}.bodyBlockList-3nW27{padding:10px}.bodyBlockListItem-3tlbT{display:flex;align-items:center;font-size:14px}.bodyBlockListItemDot-1qxPr{width:4px;height:4px;margin:2px 6px 0;border-radius:10px;background:#666}.bodyBlockListItemDescription-36GuK{color:#888;margin:0 0 0 10px;font-size:12px}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrap": "wrap-10ogN",
	"name": "name-B1k0k",
	"block": "block-3jXHC",
	"promotion": "promotion-2x0II",
	"description": "description-2F1sc",
	"icons": "icons-vrSSm",
	"more": "more-3lJ5G",
	"tags": "tags-31BdL",
	"tag": "tag-3LkG5",
	"pages": "pages-2CF6-",
	"page": "page-3p6pZ",
	"links": "links-2w9kb",
	"link": "link-3B8YN",
	"bar-block": "bar-block-1znHN",
	"barDots": "barDots-28bbb",
	"barDot": "barDot-3avIf",
	"filled": "filled-3aKlE",
	"bodyBlock": "bodyBlock-2p_0U",
	"bodyBlockTitle": "bodyBlockTitle-14-aV",
	"bodyBlockList": "bodyBlockList-3nW27",
	"bodyBlockListItem": "bodyBlockListItem-3tlbT",
	"bodyBlockListItemDot": "bodyBlockListItemDot-1qxPr",
	"bodyBlockListItemDescription": "bodyBlockListItemDescription-36GuK"
};
export default ___CSS_LOADER_EXPORT___;
