// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".wrap-1l7K-{min-height:100dvh;display:flex;align-items:center;justify-content:center}.body-1sHAX{display:flex;flex-direction:column;background:#fff;padding:20px;font-weight:bold;border-radius:12px}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrap": "wrap-1l7K-",
	"body": "body-1sHAX"
};
export default ___CSS_LOADER_EXPORT___;
