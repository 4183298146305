// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".navigation-1vLXv{display:flex;flex-direction:column;min-width:220px;background:#222;color:#eee;border-radius:10px;box-shadow:0 2px 20px 0 rgba(0,0,0,.5);padding:4px}.navigationItem-3vsu-{display:flex;gap:10px;align-items:center;justify-content:flex-start !important;margin:2px !important;padding:8px 12px !important;border-radius:8px !important;color:#ddd !important}.navigationItem-3vsu-:hover{background:rgba(255,255,255,.0666666667);color:#fff !important}.navigationItem-3vsu-.active-DgTF2{background:rgba(56,149,211,.1333333333);color:#3895d3 !important}.navigationItem-3vsu-.secondary-E8v_U{color:#999 !important}.navigationItem-3vsu-.secondary-E8v_U:hover{color:#bbb !important}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"navigation": "navigation-1vLXv",
	"navigationItem": "navigationItem-3vsu-",
	"active": "active-DgTF2",
	"secondary": "secondary-E8v_U"
};
export default ___CSS_LOADER_EXPORT___;
