// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".wrap-2e06M{display:flex;flex-direction:column;justify-content:space-evenly;align-items:center;color:#fff;min-height:90svh;text-transform:uppercase;font-size:12px;letter-spacing:1px;padding-top:76px}.stats-3fOq9{display:flex;align-items:center}.stat-3qm94{display:flex;flex-direction:column;justify-content:center;align-items:center;border-radius:50%;margin:16px;min-width:250px;min-height:250px;padding:20px;border:2px solid #ddd}.label-L4ArQ{font-size:10px}.value-24-8E{font-size:50px;font-weight:100;line-height:1}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrap": "wrap-2e06M",
	"stats": "stats-3fOq9",
	"stat": "stat-3qm94",
	"label": "label-L4ArQ",
	"value": "value-24-8E"
};
export default ___CSS_LOADER_EXPORT___;
