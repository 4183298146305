// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".wrap-new-screen-2lzDS{position:fixed;top:0;left:0;width:100%;height:100%;transform:translateY(100%);transition:.7s ease all;background:#222;z-index:10}.wrap-new-screen-2lzDS.active-3emNj{transform:translateY(0)}@keyframes animate-hFtqn{0%{opacity:0}60%{transform:translateX(0) scale(1.3)}to{opacity:1;transform:translateX(0) scale(1)}}.wrap-30jZ5.unmount-1XU0v{animation:unmount-1XU0v .7s ease forwards;overflow:hidden}.wrap-30jZ5.animated-3vgja{animation:mount-w9ey0 .4s ease forwards;overflow:hidden}.wrap-30jZ5.fixed-1757Z{overflow:hidden}.wrap-30jZ5{width:100vw;height:100svh}.base-1H1DX{height:100svh}@keyframes unmount-1XU0v{to{transform:translateY(-10%);opacity:0}}@keyframes mount-w9ey0{from{opacity:0}to{opacity:1}}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrap-new-screen": "wrap-new-screen-2lzDS",
	"active": "active-3emNj",
	"wrap": "wrap-30jZ5",
	"unmount": "unmount-1XU0v",
	"animated": "animated-3vgja",
	"mount": "mount-w9ey0",
	"fixed": "fixed-1757Z",
	"base": "base-1H1DX",
	"animate": "animate-hFtqn"
};
export default ___CSS_LOADER_EXPORT___;
