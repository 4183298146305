// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".form-1UO8O{display:flex;flex-direction:column;padding:20px 40px;min-width:300px;font-family:\"Varem\",sans-serif;flex:1}.formTitle-2LRwy{font-size:20px;text-transform:uppercase;text-align:center;margin:0 0 20px;padding:10px 0;color:#fff;border-bottom:1px solid rgba(55,55,55,.86)}.formActions-1dx80{margin:20px 0 10px;display:flex;justify-content:flex-end}.formBody-20pqN{flex:1}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"form": "form-1UO8O",
	"formTitle": "formTitle-2LRwy",
	"formActions": "formActions-1dx80",
	"formBody": "formBody-20pqN"
};
export default ___CSS_LOADER_EXPORT___;
