// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".wrap-1GL1O{align-self:center}.monthSelect-VejB2{display:flex;flex-direction:column;gap:20px;transition:.3s ease all}.monthButton-D2Fa3,.yearButton-TZxvc{font-weight:bolder;color:#aaa !important;opacity:0;transition:.3s ease all;pointer-events:none;user-select:none;padding:2px 10px !important}.visible-2PF0C{opacity:1;pointer-events:initial;user-select:initial}.selected-1QqtZ{color:#222 !important}.scroller-3NRmD{max-height:180px;overflow:hidden}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrap": "wrap-1GL1O",
	"monthSelect": "monthSelect-VejB2",
	"monthButton": "monthButton-D2Fa3",
	"yearButton": "yearButton-TZxvc",
	"visible": "visible-2PF0C",
	"selected": "selected-1QqtZ",
	"scroller": "scroller-3NRmD"
};
export default ___CSS_LOADER_EXPORT___;
