// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".iconButton-1wSj6{border:2px solid #333 !important;background:#333 !important;padding:8px !important;border-radius:100% !important}.iconButton-1wSj6 path{transition:.3s ease fill}.iconButton-1wSj6:hover{background:rgba(255,255,255,.1254901961)}.iconButton-1wSj6.remove-1ul-M:hover path{fill:red}.copy-to-clipboard-1KXeY .iconButton-1wSj6:hover path,.copied-1-GqI .iconButton-1wSj6 path{fill:#3895d3}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"iconButton": "iconButton-1wSj6",
	"remove": "remove-1ul-M",
	"copy-to-clipboard": "copy-to-clipboard-1KXeY",
	"copied": "copied-1-GqI"
};
export default ___CSS_LOADER_EXPORT___;
