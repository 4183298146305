// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".menu-39UX3{display:flex;flex-direction:column;background:#222;min-width:300px;font-weight:bolder;font-size:12px;border-right:1px solid #333;box-sizing:border-box;justify-content:space-between}.menuItem-1MKM4{display:flex;padding:10px 20px 10px 30px;color:#ccc;border-left:3px solid #333;border-bottom:1px solid rgba(255,255,255,.0666666667);cursor:pointer;opacity:0}.menuItem-1MKM4.extra-1Gct4{padding:10px 20px}.menuItem-1MKM4.sub-3Z4J6{color:#aaa;padding:0 20px 0 30px;opacity:0;max-height:0;transition:.3s ease all}.menuItem-1MKM4.sub-3Z4J6.visible-2e8iZ{max-height:50px;opacity:1;padding:10px 20px 10px 40px}.menuItem-1MKM4.extra-1Gct4.active-1nkh-{color:#fff;border-left:3px solid red}.menuItem-1MKM4.extra-1Gct4:hover{background:#282828;border-left:3px solid red}.menuItem-1MKM4.sub-3Z4J6.active-1nkh-{color:#fff;border-left:3px solid #eb802f}.menuItem-1MKM4.sub-3Z4J6:hover{background:#282828;border-left:3px solid #eb802f}.menuItem-1MKM4.active-1nkh-{color:#fff;border-left:3px solid #00bfff}.menuItem-1MKM4:hover{background:#282828;border-left:3px solid #00bfff}.vision-menu-icon-3XMRh{display:flex;justify-content:center;padding:30px}@keyframes show-menu-item-3mrLo{to{opacity:1}}.vision-content-lock-1-i6T{flex:1;display:flex;background:#262626;align-items:center;justify-content:center}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"menu": "menu-39UX3",
	"menuItem": "menuItem-1MKM4",
	"extra": "extra-1Gct4",
	"sub": "sub-3Z4J6",
	"visible": "visible-2e8iZ",
	"active": "active-1nkh-",
	"vision-menu-icon": "vision-menu-icon-3XMRh",
	"vision-content-lock": "vision-content-lock-1-i6T",
	"show-menu-item": "show-menu-item-3mrLo"
};
export default ___CSS_LOADER_EXPORT___;
