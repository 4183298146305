import React, { useEffect, useState } from 'react';
import { withRouter } from 'react-router';
import ReactGa from 'react-ga';
import { History } from 'history';
import classNames from 'classnames';

import { Env } from '@functions';

import styles from './style.scss';

type Props = {
  history: History;
  children?: JSX.Element | string;
  unmount: string | null;
  isMain?: boolean;
};

const PageWrap = ({ children, unmount, isMain, history }: Props): JSX.Element => {
  const [unmountAnimation, setUnmountAnimation] = useState(false);
  const [done, setDone] = useState(false);

  useEffect(() => {
    if (unmount) {
      // Set animation
      setUnmountAnimation(true);

      // Send page-view to GA
      if (Env.isProduction()) {
        ReactGa.pageview(unmount);
      }

      // Redirect to page after some timeout
      setTimeout(() => history.push(unmount), 700);
    }
  }, [history, unmount]);

  useEffect(() => {
    setTimeout(() => setDone(true), 1000);
  }, []);

  return (
    <>
      <div
        className={classNames(
          unmount ? styles.wrap : styles.base,
          unmountAnimation && styles.unmount,
          isMain && !done && styles.animated,
        )}
      >
        {children}
      </div>
    </>
  );
};

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
export default withRouter(PageWrap);
