// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".wrap-1lGpn{background:#fff;width:100%;min-height:100svh;display:flex;align-items:center;justify-content:center}.content-EVSdt{flex:1;display:flex;min-height:100svh}.nav-2zCMG{padding:20px 30px;display:flex;justify-content:center}.navImage-2vZCh{width:30px;opacity:.2}.loader-2lnpY{position:absolute;display:flex;align-items:center;justify-content:center;font-weight:bolder;font-size:11px;text-transform:uppercase;color:#666;transition:.5s ease all}.loader-2lnpY.loaded-1i1Cx{transform:scale(0.7);opacity:0}.loaderCircle-3IJRB{position:absolute;animation:rotate-loader-1TeCN 10s linear infinite}@keyframes rotate-loader-1TeCN{from{transform:rotate(0)}to{transform:rotate(360deg)}}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrap": "wrap-1lGpn",
	"content": "content-EVSdt",
	"nav": "nav-2zCMG",
	"navImage": "navImage-2vZCh",
	"loader": "loader-2lnpY",
	"loaded": "loaded-1i1Cx",
	"loaderCircle": "loaderCircle-3IJRB",
	"rotate-loader": "rotate-loader-1TeCN"
};
export default ___CSS_LOADER_EXPORT___;
