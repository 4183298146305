// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".table-1cnbb{margin-bottom:30px;color:#ccc}.table-1cnbb thead td{min-height:30px;padding:8px}.table-1cnbb tbody tr{border-bottom:1px solid #333;height:44px;cursor:pointer;margin-bottom:1px}.table-1cnbb tr:hover{background:rgba(0,0,0,.1647058824)}.table-1cnbb tbody td{text-align:left;vertical-align:middle;height:44px;padding:8px}.table-1cnbb tbody td:first-child,table thead td:first-child{padding-left:20px}.table-1cnbb tbody td:last-child{padding-right:20px}.tableNoData-3mA4O,.tableLoading-1k8c6{text-align:center;height:140px}.tableNoData-3mA4O{font-size:24px;color:rgba(255,255,255,.0666666667);font-weight:lighter;text-transform:uppercase}.mark-3HZ5N{border:2px solid #333;background:#333;padding:3px 6px;border-radius:4px;color:#ddd;text-align:center}.tableWrap-2IRD0{background:#222;border:2px solid #333;border-radius:10px;box-shadow:0 0 30px rgba(0,0,0,.2666666667)}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"table": "table-1cnbb",
	"tableNoData": "tableNoData-3mA4O",
	"tableLoading": "tableLoading-1k8c6",
	"mark": "mark-3HZ5N",
	"tableWrap": "tableWrap-2IRD0"
};
export default ___CSS_LOADER_EXPORT___;
