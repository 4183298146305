// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".wrap-1yL3O{display:flex;flex-direction:column;font-size:15px;background:#ccc;gap:20px;padding:20px;box-sizing:border-box;min-height:100svh}.header-RcaWB{display:flex;align-items:center;justify-content:space-between;min-height:42px;padding:0 20px;background:#222;color:#eee;border-radius:10px;box-shadow:0 2px 20px 0 rgba(0,0,0,.5)}.headerTitle-2joTc{display:flex;align-items:center}.headerIcon-umqiG{margin:1px 10px 0 0}.headerText-1l6D6{font-size:12px;text-transform:uppercase}.container-2zI2D{display:flex;align-items:flex-start;gap:20px}.tableWrapDark-2XkSh{background:#222;color:#eee;border-radius:10px;box-shadow:0 2px 20px 0 rgba(0,0,0,.5);width:100%;overflow-x:auto}.itemText-3h65J{color:#eee;font-weight:normal}.itemLink-6flmh{color:#3895d3;text-decoration:underline;font-weight:normal}.itemSubText-1p5Gw{opacity:.5;font-family:\"Open Sans\",sans-serif}.itemLink-6flmh,.itemText-3h65J{text-overflow:ellipsis;max-width:60vw;overflow:hidden;display:-webkit-box;-webkit-line-clamp:2;line-clamp:2;-webkit-box-orient:vertical}.itemLink-6flmh{white-space:nowrap}.itemBack-2uk1G{display:flex;gap:15px;align-items:center}@media(max-width: 1024px){.container-2zI2D{flex-direction:column}.navigation-2oBuX{align-self:center}}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrap": "wrap-1yL3O",
	"header": "header-RcaWB",
	"headerTitle": "headerTitle-2joTc",
	"headerIcon": "headerIcon-umqiG",
	"headerText": "headerText-1l6D6",
	"container": "container-2zI2D",
	"tableWrapDark": "tableWrapDark-2XkSh",
	"itemText": "itemText-3h65J",
	"itemLink": "itemLink-6flmh",
	"itemSubText": "itemSubText-1p5Gw",
	"itemBack": "itemBack-2uk1G",
	"navigation": "navigation-2oBuX"
};
export default ___CSS_LOADER_EXPORT___;
